import { authAxios } from '../../config/requestAxios';
import { logger } from '../../utils/helper';
import postal from 'postal';

export const FILE_UPLOAD = 'FILE_UPLOAD';
export const FILE_UPLOAD_SUCCESS = 'FILE_UPLOAD_SUCCESS';
export const FILE_UPLOAD_FAILURE = 'FILE_UPLOAD_FAILURE';
export const FILE_UPLOAD_PROGRESS = 'FILE_UPLOAD_PROGRESS';

const channel = postal.channel();
function fetchFileUploadRequesting(id, files) {
  const update = {};
  update[id] = {
    requesting: true,
    success: false,
    error: null,
    progress: 0,
    files,
  };
  return {
    type: FILE_UPLOAD,
    update,
  };
}

function fetchFileUploadRequestSuccess(id, data, files) {
  const update = {};
  update[id] = {
    requesting: false,
    success: true,
    error: false,
    progress: 100,
    data,
    files,
  };
  return {
    type: FILE_UPLOAD_SUCCESS,
    update,
  };
}

function fetchFileUploadRequestError(id, error) {
  const update = {};
  update[id] = {
    requesting: false,
    success: false,
    error,
  };
  return {
    type: FILE_UPLOAD_FAILURE,
    update,
  };
}

function fetchFileUploadProgress(id, progress) {
  const update = {};
  update[id] = {
    progress: progress,
  };
  return {
    type: FILE_UPLOAD_PROGRESS,
    update,
  };
}

export function fetchFileUpload({ id, body, url, files }) {
  return function(dispatch, getState) {
    dispatch(fetchFileUploadRequesting(id, files));
    return authAxios
      .post(url, body, {
        onUploadProgress: (ProgressEvent) => {
          const progress = Math.min(99, parseInt((ProgressEvent.loaded / ProgressEvent.total) * 100));
          dispatch(
            fetchFileUploadProgress(
              id,
              progress,
            ),
          );
          logger('Progress', (ProgressEvent.loaded / ProgressEvent.total) * 100);
        },
      })
      .then((response) => {
        const files = getState().fileUpload[id].files;
        dispatch(fetchFileUploadProgress(id, 100));
        dispatch(fetchFileUploadRequestSuccess(id, response.data, files));
        channel.publish('finishedUploadFile', { url: url, files: files });
      })
      .catch((err) => {
        const {
          response: {
            data: {
              data: { code, data },
            },
          },
        } = err;
        // INFORMO CON UNA ALERTA AL USUARIO CUANDO SUPERO LA CANTIDAD MAXIMA PERMITIDA EN LA SUBIDA DE UN ARCHIVO O CUANDO EL ARCHIVO ES INVALIDO.
        if (['LIMIT_FILE_SIZE', 'DOCUMENT_INVALID'].includes(code)) {
          const typeResponse = {
            LIMIT_FILE_SIZE: [
              {
                tipo: 'warning',
                titulo: 'Error',
                data: 'El archivo es demasiado grande',
              },
            ],
            DOCUMENT_INVALID: [
              {
                tipo: 'warning',
                titulo: 'Error',
                data,
              },
            ],
          };
          channel.publish('notification', typeResponse[code]);
        }
        dispatch(fetchFileUploadRequestError(id, err));
      });
  };
}

export function fetchFileUploadGoogleDrive({ id, body, url, files }) {
  return function(dispatch, getState) {
    dispatch(
      fetchFileUploadRequesting(
        id,
        files.map((file) => ({ ...file, uploading: true })),
      ),
    );

    return authAxios({
      url,
      method: 'POST',
      headers: {
        AuthorizationGoogleDrive: `Bearer ${window.fileUploadGoogleDrive.oauthToken}`,
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
      },
      data: body,
    })
      .then((response) => {
        let files = getState().fileUpload[id].files;
        files = files.map((file) => ({ ...file, uploading: false }));
        dispatch(fetchFileUploadRequestSuccess(id, response.data, files));
      })
      .catch((err) => dispatch(fetchFileUploadRequestError(id, err)));
  };
}
