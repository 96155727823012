import React, { Component } from 'react';
import _ from 'lodash';
import { v1 as uuid } from 'uuid'
import objectPath from 'object-path';
import postal from 'postal';
import { equalRegEx } from '../../../utils/utils';
import { connect } from 'react-redux';
import { onChangeDeleteModelActions, setFieldValidation } from '../../../store/actions/ModelActions';
import MountComponent from '../../MountComponent/MountComponent';
import { fetchTable } from '../../../store/actions/TableActions';
import { propsToFilters } from '../../GridNew/helper';
import { requestAxios } from '../../../config/requestAxios';

class Repeater extends Component {
  constructor(props) {
    super(props);
    this.state = {
      originalComponets: { pantalla: this.props.pantalla },
      components: { pantalla: this.props.pantalla } || {},
      repeat: null,
      delete: false,
      formid: this.props.formid || '',
      repeatLabel: this.props['data-repeat_labels']
        ? equalRegEx('true').test(this.props['data-repeat_labels'])
        : true,
      data: [],
      init: true,
      success: false,
      alow_insert: this.props['data-alow_insert']
        ? equalRegEx('true').test(this.props['data-alow_insert'])
        : true,
      externalFilters: propsToFilters(props['data-valores_formulario']),
    };
    this.position = 0;
    this.componentesMontados = [];
    this.componentesMontadosUpdate = [];
    this.channel = postal.channel();
  }

  conformRequestParams = (tablename, action, rowData) => ({
    IBLE_P_COD_ACCION: 'SUBMIT_AUTOMATICO',
    IBLE_P_CAMPO: 'SUBMIT_AUTOMATICO',
    IBLE_P_EVENTO: 'CLICK',
    TABLA: tablename || '',
    SUBMIT_ACTION: action,
    ...rowData,
  });

  removeElement = (index, sufijo, UUID) => {
    const tablename = objectPath.get(this.props.model, 'TABLA', '');
    const params = this.conformRequestParams(tablename, 'DELETE', { UUID: UUID });
    return requestAxios
      .post(
        '/ible_con/EVENTOS_CAMPOS/' +
          params.IBLE_P_COD_ACCION +
          '/' +
          params.IBLE_P_CAMPO,
        params,
      )
      .then((response) => {
        if (response.data.acciones) {
          this.channel.publish(
            'notification',
            response.data.acciones.acciones_alert,
          );
        }
      })
      .catch((error) => {
        console.error(error);
        this.channel.publish('notification', [
          { tipo: 'error', titulo: '', data: 'Ocurrio un error interno' },
        ]);
      });
  };

  componentDidMount() {
    if (this.props.validate) this.props.dispatch(setFieldValidation(false))
    this.fetchMoreData();
    this.channel.subscribe(`${this.props.formid}:OnClick`, (data) => {
      if (objectPath.get(data.props, 'sufijo') !== undefined) {
        this.componentesMontados.map((item, index) => {
          if (item.props.sufijo === data.props.sufijo) {
            if (this.state.data.length > 0) {
              this.componentesMontados.splice(index, 1);
              this.handleDeletePropertyModel(item.props.sufijo);
              this.removeElement(index, item.props.sufijo, data.props.UUID);
            } else {
              this.componentesMontados.splice(index, 1);
              this.handleDeletePropertyModel(item.props.sufijo);
            }
          }
          return null;
        });
      }
    });
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps.validar, this.props.validar) && this.props.validar === 'validar') {
      this.props.dispatch(setFieldValidation(true))
    }
  }

  fetchMoreData = async () => {
    let filters = {};
    if (!_.isEmpty(this.props.model) && !_.isEmpty(this.state.externalFilters)) {
      const _model = _.keys(this.props.model);
      _.keys(this.state.externalFilters).map((element) => {
        if (_model.indexOf(element) > -1) {
          filters = { ...filters, ...{ [element]: this.props.model[element] } };
        }
        return null;
      });
    }
    const paramsMap = {
      IBLE_ALIAS: this.props['data-alias'],
      skip: 0,
      ...filters,
      cache: false,
    };
    const result = await fetchTable(paramsMap);
    if (!result.error) {
      if (objectPath.get(result, 'data') !== undefined) {
        if (result.data.length > 0) {
          this.handleUpdateData(
            objectPath.get(result, 'data') !== undefined ? result.data : [],
          );
        } else this.componentesMontados.push(this.fnMountComponents(true));
        this.setState({
          data: objectPath.get(result, 'data') !== undefined ? result.data : [],
          init: false,
          success: true,
        });
      } else this.componentesMontados.push(this.fnMountComponents(true));
    } else {
      this.componentesMontados.push(this.fnMountComponents(true));
    }
    this.setState({ init: false, success: true });
  };

  handleOnclickPlus = (evt) => {
    this.position = this.position + 1;
    this.componentesMontados.push(this.fnMountComponents(false));
    const newPantalla = 'pantalla_' + uuid();
    this.setState({
      components: {
        ...this.state.components,
        ...{ [newPantalla]: this.state.components.pantalla },
      },
      repeat: newPantalla,
    });
  };

  handleUpdateData = async (data) => {
    for (let i = 0; i < data.length; i++) {
      this.position = this.position + 1;
      const comp = await this.fnMountComponentsUpdate(data[i], i, i === 0);
      this.componentesMontados.push(comp);
      const newPantalla = 'pantalla_' + uuid();
      this.setState({
        components: {
          ...this.state.components,
          ...{ [newPantalla]: this.state.components.pantalla },
        },
        repeat: newPantalla,
      });
    }
  };

  deleteElementComponet = (stateComponents, keyLastComponent) => {
    let newStateComponents = {};
    _.keys(stateComponents).map((item) => {
      if (item !== keyLastComponent) {
        newStateComponents = {
          ...newStateComponents,
          ...{ [item]: stateComponents[item] },
        };
      }
      return null;
    });
    return newStateComponents;
  };

  handleOnclickDelete = async (evt) => {
    if (_.size(this.componentesMontados) > 1) {
      const componentMount = this.componentesMontados;
      /**
       * Listado de repeater en pantalla.
       */
      let stateComponents = this.state.components;
      /**
       * Ultimo elemento seteado en pantalla.
       */
      const keyLastComponent =
        _.keys(stateComponents)[_.keys(stateComponents).length - 1];
      /**
       * Buscando la version de los componentes a eliminar.
       */
      const _v = componentMount[_.size(componentMount) - 1].props.sufijo;
      /**
       * Eliminando el ùltimo elemento del puesto en pantalla.
       */
      stateComponents = this.deleteElementComponet(
        stateComponents,
        keyLastComponent,
      );
      const result = componentMount.filter(
        (item, index) => index !== _.size(componentMount) - 1,
      );
      this.componentesMontados = result;
      /**
       * Metodo que elimina del modelo de la pantalla el ùltimo elemento.
       */
      this.handleDeletePropertyModel(_v, stateComponents);
    }
  };

  /**
   *
   * @param {*} _v
   * @Description Eliminando un elemento del modelo global de la pantalla
   */
  handleDeletePropertyModel = (_v, stateComponents) => {
    const keysModel = _.keys(this.props.model);
    keysModel.map((item) => {
      if (item.indexOf(_v) > -1) {
        this.props.dispatch(
          onChangeDeleteModelActions(this.props.formid, { name: item, value: '' }),
        );
      }
      return null;
    });
    this.position = this.position - 1;
    this.setState({
      delete: !this.state.delete,
      components: stateComponents,
    });
  };

  /**
   * @description Se crea linea por linea el repeater
   * @param {*} items
   * @param {*} sufijo
   * @param {*} init
   * @param {*} row
   */
  renameProperty = (items, sufijo, init, row = {}) => {
    if (items.children.length > 0) {
      items.children.map((item) => {
        return this.renameProperty(item, sufijo, init, row);
      });
    }
    if (objectPath.get(items.props, 'name') !== undefined) {
      if (Object.keys(row).length > 0) {
        if (objectPath.get(row, [items.props.name.split('.')[0]]) !== undefined) {
          items.props.value = row[items.props.name.split('.')[0]];
        } else if (
          objectPath.get(row, [
            items.props.name.replace(
              '.' +
                items.props.name.split('.')[items.props.name.split('.').length - 1],
              '',
            ),
          ]) !== undefined
        ) {
          items.props.value =
            row[
              items.props.name.replace(
                '.' +
                  items.props.name.split('.')[
                    items.props.name.split('.').length - 1
                  ],
                '',
              )
            ];
        }
      } else items.props.value = '';
      items.props.old_name = items.props.name + '__repeated';
      // items.props.name=items.props.name+'.'+sufijo;
      if (sufijo === 0) {
        items.props.name =
          items.props.name.replace('.' + items.props.name.split('.')[1], '') +
          '.' +
          sufijo;
      } else {
        items.props.name = items.props.name.replace(
          items.props.name.split('.')[items.props.name.split('.').length - 1],
          sufijo,
        );
      }
      return items;
    } else if (
      objectPath.get(items, 'props.type') === 'label' &&
      this.state.repeatLabel === false &&
      init === false
    ) {
      items.props.show = false;
      return items;
    } else if (objectPath.get(items, 'props.type') === 'label' && init) {
      items.props.show = true;
      return items;
    } else if (
      objectPath.get(items, 'props.deleterow') === 'true' &&
      objectPath.get(items, 'props.type') === 'button'
    ) {
      items.props.sufijo = sufijo;
      if (objectPath.get(row, 'UUID') !== undefined) items.props.UUID = row.UUID;
      return items;
    } else return items;
  };

  searchVersionRepeater = (items, version) => {
    if (items.children.length > 0) {
      items.children.map((item) => {
        return this.searchVersionRepeater(item, version);
      });
    }
    if (objectPath.get(items.props, 'name') !== undefined) {
      const _v =
        items.props.name.indexOf('.') > -1 ? items.props.name.split('.')[1] : '';
      version = version.push(_v || '');
      return version;
    } else return version;
  };

  fnMountComponentsUpdate = (row, pos, init) => {
    const repeater = {
      pantalla: {
        props: {
          type: 'container',
          class: this.props.class,
        },
        key: uuid(),
        children: this.renameProperty(
          this.state.originalComponets.pantalla,
          pos,
          init,
          row,
        ).children,
      },
    };
    const component = (
      <MountComponent
        key={uuid()}
        externalPantalla={repeater}
        formid={this.state.formid}
        sufijo={this.position}
        isRepeaterComponent={true}
      />
    );
    return component;
  };

  fnMountComponents = (init) => {
    const repeater = {
      pantalla: {
        props: {
          type: 'container',
          class: this.props.class,
        },
        key: uuid(),
        children: this.renameProperty(
          this.state.originalComponets.pantalla,
          this.position,
          init,
        ).children,
      },
    };
    const component = (
      <MountComponent
        key={uuid()}
        externalPantalla={repeater}
        formid={this.state.formid}
        sufijo={this.position}
        isRepeaterComponent={true}
      />
    );
    return component;
  };

  btnDelete = () => {
    if (
      this.componentesMontados.length > 1 ||
      this.componentesMontadosUpdate.length > 1
    ) {
      return (
        <button
          type="button"
          className="btn btn-secondary danger btn btn-secondary"
          onClick={this.handleOnclickDelete}
        >
          <i className="fas fa-trash"></i>
        </button>
      );
    } else return null;
  };

  btnPlus = () => {
    if (this.state.alow_insert) {
      return (
        <button
          type="button"
          className="repetir btn btn-secondary info btn btn-secondary"
          onClick={this.handleOnclickPlus}
        >
          <i className="ti-plus"></i>Agregar
        </button>
      );
    } else return null;
  };

  render() {
    if (!this.state.init && this.state.success && this.state.data.length === 0) {
      const _components = this.componentesMontados;
      return (
        <div className="repeater col-12 col-xl-12">
          <div
            id="detalle-right"
            role="group"
            className="float-right d-lg-block btn-group uno"
          >
            {this.btnPlus()}
            {this.btnDelete()}
          </div>
          {_components.map((cmp) => {
            return cmp;
          })}
        </div>
      );
    } else if (
      !this.state.init &&
      this.state.success &&
      this.state.data.length > 0
    ) {
      const _components = this.componentesMontados;
      return (
        <div className="repeater col-12 col-xl-12">
          <div
            id="detalle-right"
            role="group"
            className="float-right d-lg-block btn-group dos"
          >
            {this.btnPlus()}
          </div>
          {_components.map((cmp) => cmp)}
        </div>
      );
    } else return null;
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    model: _.get(state.model, ['model', ownProps.formid], {}),
    validate: _.get(state.model, 'validation', false)
  };
};

export default connect(mapStateToProps)(Repeater);
