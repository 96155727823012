import { requestAxios, updateAxios } from '../../config/requestAxios';
import objectPath from 'object-path';
import postal from 'postal';

export const USER_AUTH_INIT = 'USER_AUTH_INIT';
export const USER_AUTH_SUCCESS = 'USER_AUTH_SUCCESS';
export const USER_AUTH_ERROR = 'USER_AUTH_ERROR';
const channel = postal.channel();

const userAuthInit = () => {
  return {
    type: USER_AUTH_INIT,
    payload: {
      init: true,
    },
  };
};

export const userAuthReset = () => {
  return {
    type: USER_AUTH_SUCCESS,
    payload: {
      init: false,
      success: false,
      error: false,
      user: {},
    },
  };
};

const userAuthSuccess = (data) => {
  return {
    type: USER_AUTH_SUCCESS,
    payload: {
      init: false,
      success: true,
      error: false,
      user: data,
    },
  };
};

const userAuthError = (err) => {
  return {
    type: USER_AUTH_ERROR,
    payload: {
      init: false,
      success: false,
      error: err,
    },
  };
};

export const userAuth = (data) => {
  return function (dispatch) {
    dispatch(userAuthInit());
    channel.publish('FORM:INIT');
    if (!localStorage.getItem('enp_id')) {
      requestAxios.defaults.headers.Authorization = '';
    }
    requestAxios
      .post('/ible/login', data)
      .then((response) => {
        channel.publish('REQUEST:END');
        if (objectPath.get(response.data.type) === 'success') {
          const result = response.data;
          localStorage.setItem('NOMBRE', result.data.user.username);
          localStorage.setItem('token', result.data.token);
          localStorage.setItem('U_ID', result.data.user.U_ID);
          localStorage.setItem('enp_id', result.data.user.enp_id);
          localStorage.setItem('roles', result.data.user.roles);
          localStorage.setItem('TG_ID', result.data.user.id);
          updateAxios(function () {
            dispatch(userAuthSuccess(result.data.user));
          });
        } else {
          dispatch(userAuthError(response.data));
        }
      })
      .catch((err) => {
        dispatch(userAuthError(err));
      });
  };
};

export const userAuthByUrl = (data) => {
  return function (dispatch) {
    dispatch(userAuthInit());
    channel.publish('FORM:INIT');
    if (!localStorage.getItem('token')) {
      requestAxios.defaults.headers.Authorization = '';
    }
    requestAxios
      .post('/ible/login-ui', data)
      .then((response) => {
        channel.publish('REQUEST:END');
        if (objectPath.get(response.data.type) === 'success') {
          const { data } = response.data;
          if (data.token) {
            localStorage.setItem('token', data.token);
            localStorage.setItem('NOMBRE', data.user.username);
            localStorage.setItem('token', data.token);
            localStorage.setItem('U_ID', data.user.U_ID);
            localStorage.setItem('enp_id', data.user.enp_id);
            localStorage.setItem('roles', data.user.roles);
            updateAxios(function () {
              dispatch(userAuthSuccess(data.user));
            });
          } else {
            dispatch(userAuthError(response.data));
          }
        } else {
          dispatch(userAuthError(response.data));
        }
      })
      .catch((err) => {
        dispatch(userAuthError(err));
      });
  };
};
