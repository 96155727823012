import React, { useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import './Modal.scss';
import postal from 'postal';

const Modal = ({ className, title, children, open, onBack }) => {
  const modalRef = useRef(null);
  const [state, setState] = useState({
    open: open,
    modalCildren: null,
    submit: {},
    submited: false,
    clicked: false
  });

  const channel = postal.channel();

  useEffect(() => {
    const div = modalRef.current;
    if (div !== null) {
      div.addEventListener('keydown', handleKeyDown, false);
    }

    const dataSubmittedHandler = ({ refresh, keepOpenModal }) => {
      if (refresh && !keepOpenModal) {
        handleOnBack();
      }
    };

    channel.subscribe('dataSubmitted', dataSubmittedHandler);

    return () => {
      if (div !== null) {
        div.removeEventListener('keydown', handleKeyDown, false);
      }
    };
  }, []);

  useEffect(() => {
    if (state.open !== open) {
      setState((prevState) => ({
        ...prevState,
        open: open
      }));
    }
  }, [open]);

  const handleKeyDown = (e) => {
    const evt = e || window.event;
    if (state.open && evt.keyCode === 27) {
      channel.publish('close:modal');
      handleOnBack();
    }
  };

  const handleOnBack = () => {
    setState((prevState) => ({
      ...prevState,
      open: false,
      link: ''
    }));
    onBack();
  };

  if (state.open) {
    return (
      <div ref={modalRef} className={cn(className, 'mdal')}>
        <div className="mdalnav">
          <i className="mdalback ti-arrow-left" onClick={handleOnBack} />
          {title && <label className="mdaltitle">{title}</label>}
        </div>
        <div className="mdalcontainer">{children}</div>
      </div>
    );
  } else {
    return null;
  }
};

Modal.defaultProps = {
  className: '',
  title: '',
  open: true,
  onBack: () => {}
};

export default Modal;
