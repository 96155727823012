import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import postal from 'postal';
import Modal from '../../Modal/Modal';
import { onChangeModelActions } from '../../../store/actions/ModelActions';
import { mountPantalla } from '../../GridNew/helper';

const WebButton = (props) => {
  const {
    msgpage,
    icon,
    disabled,
    formid,
    model,
    button_link,
    dispatch,
    class: buttonClass,
  } = props

  const [state, setState] = useState({
    message: msgpage && typeof msgpage === 'string' ? msgpage : msgpage && typeof msgpage === 'object' ? msgpage.join(' ') : 'Confirmar',
    icon: icon || 'fa fa-check',
    disabled: disabled || false,
    showModal: false,
    formInit: true,
  });

  const channel = postal.channel();

  useEffect(() => {
    const disabledButtonHandler = () => {
      if (state.formInit) setState((prevState) => ({ ...prevState, disabled: false }));
    };

    const formInitHandler = () => {
      setState((prevState) => ({ ...prevState, formInit: false, disabled: true }));
    };

    const requestEndHandler = () => {
      setState((prevState) => ({ ...prevState, disabled: false, formInit: true }));
    };

    channel.subscribe('disabledButton', disabledButtonHandler);
    channel.subscribe('FORM:INIT', formInitHandler);
    channel.subscribe('REQUEST:END', requestEndHandler);
  }, [state.formInit]);

  const handleOnClick = (evt) => {
    if (!button_link === false) {
      const link = { pantalla: button_link };
      const _modalChildren = mountPantalla(formid, link.pantalla, model);
      setState((prevState) => ({
        ...prevState,
        showModal: true,
        modelModal: model,
        modalChildren: _modalChildren,
      }));
      dispatch(
        onChangeModelActions(formid, {
          name: 'SUBMIT_ACTION',
          value: 'INSERT',
        })
      );
    } else {
      channel.publish(`${formid}:OnClick`, {
        props: { ...props },
      });

      if (props.handleOnClick !== undefined) {
        setState((prevState) => ({ ...prevState, disabled: true }));
        props.handleOnClick(evt);
        channel.publish('refreshDashList');
      }
    }
  };

  return (
    <>
      <Modal
        className="modal-providers"
        open={state.showModal}
        onBack={() => {
          setState((prevState) => ({ ...prevState, showModal: false }));
        }}
      >
        {state.modalChildren}
      </Modal>
      <Button
        className={buttonClass}
        disabled={state.disabled}
        bsstyle="primary"
        type="submit"
        onClick={handleOnClick}
      >
        {state.message}
      </Button>
    </>
  );
};

const mapStateToProps = (state, ownProps) => ({
  form: state.form,
  model: _.get(state.model, ['model', ownProps.formid], {}),
});

export default connect(mapStateToProps)(WebButton);
