import { deleteAuthorizationAxios, requestAxios, ssoRequest } from '../../config/requestAxios';

export const LOGOUT_INIT = 'USER_REQUEST_INIT';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_ERROR = 'LOGOUT_ERROR';

const logoutInit = () => {
  return {
    type: 'LOGOUT_INIT',
    payload: {
      init: true,
    },
  };
};

const logoutSuccess = (data) => {
  return {
    type: 'LOGOUT_SUCCESS',
    payload: {
      init: false,
      success: true,
      error: false,
      logout: data,
    },
  };
};

const logoutError = (err) => {
  return {
    type: 'LOGOUT_ERROR',
    payload: {
      init: false,
      success: false,
      error: err,
    },
  };
};

export const requestLogout = () => {
  return function (dispatch) {
    dispatch(logoutInit());
    // Borrar cookies y localStorage
    localStorage.clear();
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i];
      const eqPos = cookie.indexOf('=');
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
    }
    requestAxios
      .get('/ible/logout')
      .then((response) => {
        deleteAuthorizationAxios(async () => {
          const result = response.data;
          localStorage.setItem('id', result.data._id);
          dispatch(logoutSuccess(result.data));
        });
      })
      .catch((error) => {
        dispatch(logoutError(error));
      });
  };
};

export const requestLogoutWithSSO = () => {
  return function (dispatch) {
    dispatch(logoutInit());
    localStorage.clear();
    ssoRequest
      .delete('/api/v1/auth/logout')
      .then((response) => {
        const result = response?.data;
        const path = result?.data?.path;
        deleteAuthorizationAxios(() => {
          dispatch(logoutSuccess({ path }));
        });
      })
      .catch((error) => {
        dispatch(logoutError(error));
      });
  };
};
