import objectPath from 'object-path';
import jwt from 'jsonwebtoken';

import { requestAxios, updateAxios, ssoRequest } from '../../config/requestAxios';
import { getQueryParams } from '../../utils/utils';

export const LOGGED_REQUEST_INIT = 'LOGGED_REQUEST_INIT';
export const LOGGED_REQUEST_SUCCESS = 'LOGGED_REQUEST_SUCCESS';
export const LOGGED_REQUEST_ERROR = 'LOGGED_REQUEST_ERROR';

const userRequestInit = () => {
  return {
    type: LOGGED_REQUEST_INIT,
    payload: {
      init: true,
      error: false,
    },
  };
};

const userRequestSuccess = (data) => {
  return {
    type: LOGGED_REQUEST_SUCCESS,
    payload: {
      init: false,
      success: true,
      error: false,
      logged: data || {},
    },
  };
};

const userRequestError = ({ error, logged }) => {
  return {
    type: LOGGED_REQUEST_ERROR,
    payload: {
      init: false,
      success: false,
      error: error,
      logged: logged,
    },
  };
};

export const getUserLogged = () => {
  return function (dispatch) {
    dispatch(userRequestInit());
    updateAxios(function () {
      requestAxios
        .get('/ible/logged')
        .then((response) => {
          const result = response.data;
          if (result.login_type === 'error') {
            dispatch(userRequestError({ error: true, logged: result }));
            if (result.data._id) localStorage.setItem('id', result.data._id);
            return;
          }
          if (result.data._id) localStorage.setItem('id', result.data._id);
          objectPath.get(result.data.user, 'id') !== undefined
            ? localStorage.setItem('U_ID', result.data.user.id)
            : localStorage.setItem('U_ID', '');
          const queryParams = getQueryParams();
          if (queryParams.redirecto) {
            result.data.user.redirecto = queryParams.redirecto;
          }
          dispatch(userRequestSuccess(result.data.user));
        })
        .catch((err) => {
          dispatch(userRequestError({ error: true, logged: err }));
        });
    });
  };
};

export const getUserLoggedWithSigleSignOn = () => {
  return function (dispatch) {
    dispatch(userRequestInit());
    const accessToken = document?.location?.search;
    if (accessToken) {
      const [valuesAccessToken] = accessToken.split('&');
      const [, value] = valuesAccessToken.split('=');
      const token = value.replace(/Bearer/, '').replace(/%20/, '');
      localStorage.setItem('enp_id', token);
    }

    updateAxios(function () {
      ssoRequest
        .get('/api/v1/auth/logged')
        .then(({ data }) => {
          const accessToken = data?.data?.accessToken;
          ssoRequest.get('/api/v1/cert').then(async ({ data }) => {
            const cert = data?.data?.cert;
            const algorithm = data?.data?.algorithm;

            if (accessToken) {
              localStorage.setItem('enp_id', accessToken);
            }
            const user = await verifyTokenAsymmetric(accessToken, cert, algorithm)
            localStorage.setItem('NOMBRE', user?.name);
            localStorage.setItem('cod_user', user?.cod_user);
            localStorage.setItem('roles', user?.roles);
            localStorage.setItem('U_ID', accessToken);
            const userAction = {
              username: user?.cod_user,
              roles: user?.roles,
              nombre: user?.name,
              perfil: user?.profile,
            };
            // localStorage.setItem('TG_ID', '');
            dispatch(userRequestSuccess(userAction));
          });
        })
        .catch((err) => {
          dispatch(userRequestError({ error: true, logged: err }));
        });
    });
  };
};

const verifyTokenAsymmetric = function (token, privateKey, algorithm) {
  return new Promise((resolve, reject) => {
    jwt.verify(
      token,
      privateKey,
      {
        algorithms: [algorithm],
      },
      (err, payload) => {
        if (err) return reject(err);
        return resolve(payload);
      },
    );
  });
};
