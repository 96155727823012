export const MODEL_SUCCESS = 'MODEL_SUCCESS';
export const ONCHANGE_MODEL_SUCCESS = 'ONCHANGE_MODEL_SUCCESS';
export const ONSET_MODEL_VALIDATION_SUCCESS = 'ONSET_MODEL_VALIDATION_SUCCESS';
export const ONCHANGE_DELETE_MODEL_SUCCESS = 'ONCHANGE_DELETE_MODEL_SUCCESS';
export const ONCHANGE_MODEL_SUCCESS_BY_QUERY_STRING = 'ONCHANGE_MODEL_SUCCESS_BY_QUERY_STRING';
export const ROWINFO_SUCCESS = 'ROWINFO_SUCCESS';
export const ADD_MODALID = 'ADD_MODALID';
export const DEL_MODALID = 'DEL_MODALID';
export const FIELD_VALIDATION = 'FIELD_VALIDATION';
export const FIELD_VALIDATION_VALUE = 'FIELD_VALIDATION_VALUE';
export const VALIDATE_FIELDS = 'VALIDATE_FIELDS';

const modelAction = (
  type,
  { data = null, init = false, success = false, error = false },
) => {
  return {
    type,
    payload: { ...data, init, success, error },
  };
};

// Acciòn para guardar en el store la url de la modal que recièn se
// mostrò en pantalla
export const addModalId = (Id) => {
  return function (dispatch) {
    dispatch(modelAction('ADD_MODALID', { data: { [Id]: true }, success: true }));
  };
};

// Acciòn para eliminar del store la url de la modal que recièn se
// elimino de la pantalla
export const removeModalId = (Id) => {
  return function (dispatch) {
    dispatch(modelAction('DEL_MODALID', { data: { [Id]: false }, success: true }));
  };
};

export const ModelSuccess = (id, data) => {
  return {
    type: MODEL_SUCCESS,
    payload: {
      status: true,
      data: data,
      formid: id,
    },
  };
};

const onChangeModelSuccess = (id, data) => {
  return {
    type: ONCHANGE_MODEL_SUCCESS,
    payload: {
      status: true,
      data: data,
      formid: id,
    },
  };
};

const onChangeModelByQueryParams = (id, data) => {
  return {
    type: ONCHANGE_MODEL_SUCCESS_BY_QUERY_STRING,
    payload: {
      status: true,
      data: data,
      formid: id,
    },
  };
};

const onSetModelValidationSuccess = (formid, name, isVisible) => {
  return {
    type: ONSET_MODEL_VALIDATION_SUCCESS,
    payload: {
      status: true,
      formid,
      name,
      isVisible,
    },
  };
};

const onChangeDeleteModelSuccess = (id, data) => {
  return {
    type: ONCHANGE_DELETE_MODEL_SUCCESS,
    payload: {
      status: true,
      data: data,
      formid: id,
    },
  };
};

const RowInfoSuccess = (data) => {
  return {
    type: ROWINFO_SUCCESS,
    payload: {
      status: true,
      getRowInfo: data,
    },
  };
};

const fieldValidation = (data) => {
  return {
    type: FIELD_VALIDATION,
    payload: {
      data: data,
    },
  };
};

const fieldValidationValue = (formid, name, isValidate) => {
  return {
    type: FIELD_VALIDATION_VALUE,
    payload: {
      formid,
      name,
      isValidate,
    },
  };
};

const fieldsValidate = (formid, field) => {
  return {
    type: VALIDATE_FIELDS,
    payload: {
      formid,
      field,
    },
  };
};

export const getRowInfo = (params) => {
  return function (dispatch) {
    dispatch(RowInfoSuccess(params));
  };
};

export const modelActions = (formid, params) => {
  return function (dispatch) {
    dispatch(ModelSuccess(formid, params));
  };
};

export const onChangeModelActions = (formid, params) => {
  return function (dispatch) {
    dispatch(onChangeModelSuccess(formid, params));
  };
};

export const onChangeModelByQueryParamsAction = (formid, params) => {
  return function (dispatch) {
    dispatch(onChangeModelByQueryParams(formid, params));
  };
};

export const onSetModelValidation = (formid, name, isVisible) => {
  return function (dispatch) {
    dispatch(onSetModelValidationSuccess(formid, name, isVisible));
  };
};

export const onChangeDeleteModelActions = (formid, params) => {
  return function (dispatch) {
    dispatch(onChangeDeleteModelSuccess(formid, params));
  };
};

export const setFieldValidation = (data) => {
  return function (dispatch) {
    dispatch(fieldValidation(data));
  };
};

export const onValidateFieldsValue = (formid, name, isValidate) => {
  return function (dispatch) {
    dispatch(fieldValidationValue(formid, name, isValidate));
  };
};

export const FieldsToValidate = (formid, field) => {
  return function (dispatch) {
    dispatch(fieldsValidate(formid, field));
  };
};
