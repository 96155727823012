import objectPath from 'object-path';
import { onChangeModelActions } from '../../store/actions/ModelActions';
import moment from 'moment';
import _ from 'lodash';
import { equalRegEx } from '../../utils/utils';

export const checkRoles = (roles, _roles) => {
  let response = false;
  const pageHidden = _roles.split(',');
  pageHidden.forEach((item) => {
    roles.forEach((rol) => {
      if (item === rol) response = true;
      else {
        if (item.charAt(0) === '!') {
          const _rol = item.substr(1, item.length - 1);
          if (roles.indexOf(_rol) <= 0) response = false;
        }
      }
    });
  });
  return response;
};

export const readOnly = (props) => {
  if (objectPath.get(props, 'readonly')) return true;
  if (props.roles_readonly) {
    const _value = checkRoles(props.roles, props.roles_readonly);
    return _value;
  } else return false;
};

export const getXfValue = (that, data, type) => {
  /**
   * Busca en el modelo de datos si el campo está dentro del XF
   */
  let _value = '';
  const _xf = objectPath.get(data, 'XF');
  let xf_data = _xf !== undefined && _xf !== null ? data.XF : '{}';
  xf_data = JSON.parse(xf_data);
  const xf_type = objectPath.get(xf_data, 'TYPE') !== undefined ? xf_data.TYPE : '';
  if (_.keys(xf_data).indexOf(that.props.name) > -1) {
    _value = xf_data[that.props.name];
  } else if (
    _.keys(xf_data).indexOf(that.props.name.replace([xf_type] + '.', '')) > -1
  ) {
    const item = that.props.name.replace([xf_type] + '.', '');
    _value = xf_data[item];
  } else if (data[that.props.name]) {
    /**
     * Si el campo no está dentro de los XF se busca en el modelo en general
     */
    _value = data[that.props.name];
  }
  /**
   * Seteo de datos en componentes dentro de un repeater
   */
  if (_.get(that.props, 'old_name') !== undefined) {
    let _name = '';
    if (that.props.old_name.indexOf('__repeated') > -1) {
      _name = that.props.old_name.split('__')[0];
    }
    _value = data[_name];
  }
  if (that.state.value !== _value) {
    if (!_.isEmpty(_value)) {
      if (type === 'dates') {
        _value = _value.substring(0, 10)
      };
      if (type === 'datetime') {
        _value = moment(_value).format()
      };
    }
    that.setState({ value: _value, validateXf: true });
    /**
     * Si el componente se está inicializando se publica en el modelo global
     * de la pantalla el nuevo valor
     */
    that.props.dispatch(
      onChangeModelActions(that.props.formid, {
        name: that.props.name,
        value: _value,
      }),
    );
  }
};

const propsOrModelValue = (propsValue, name, nextModel) => {
  let value = propsValue;
  for (const i in nextModel) {
    if (typeof nextModel[i] === 'object') {
      _.keys(nextModel[i]).forEach((item) => {
        if (
          name === item &&
          !_.isEmpty(nextModel[i][item]) &&
          nextModel[i][item] !== propsValue
        ) {
          value = nextModel[i][item];
        }
      });
    } else if (
      name === i &&
      !_.isEmpty(nextModel[i]) &&
      nextModel[i] !== propsValue
    ) {
      value = nextModel[i];
    }
  }
  return value;
};

export const initXfAndValue = (that, type = 'nodates') => {
  if (that.props.value !== undefined && that.props.value !== '') {
    let value = propsOrModelValue(
      that.props.value,
      that.props.name,
      that.props.model,
    );
    if (type === 'datetime' || type === 'dates') value = moment(value);
    if (equalRegEx('null').test(value)) value = null
    that.setState({ value: value });
    that.props.dispatch(
      onChangeModelActions(that.props.formid, {
        name: that.props.name,
        value: value,
      }),
    );
  } else if (!_.isEmpty(that.props.old_name)) {
    that.setState({ value: '' });
    that.props.dispatch(
      onChangeModelActions(that.props.formid, {
        name: that.props.name,
        value: '',
      }),
    );
  } else if (that.props.model) {
    const model = that.props.model;
    getXfValue(that, model, type);
  }
};

export const fnFields = (field) => {
  const ptr = /^[0-9]*$/;
  if (field.indexOf('.') > -1) {
    const arrayField = field.split('.');
    if (arrayField.length === 2 && ptr.test(arrayField[1])) {
      return arrayField[0];
    }
  } else return field;
};

export const attrChangeModel = (that, name, currentModel, nextModel) => {
  let onChange = false;
  let value = _.get(that.props, ['value'], '');
  for (const i in nextModel) {
    if (typeof nextModel[i] === 'object') {
      _.keys(nextModel[i]).forEach((item) => {
        const nextVal = nextModel[i][item];
        const currentVal = _.get(currentModel[i], [item], {});
        if (name === item && nextVal !== currentVal) {
          value = nextVal;
          onChange = true;
        }
      });
    } else if (name === i && nextModel[i] !== currentModel[i]) {
      value = nextModel[i];
      onChange = true;
    }
  }
  return { onChange: onChange, value: value };
};

// Validacion adicional de XF para el componente Chechbox
export const validateXfValue = (that, nextModel, data) => {
  // Obteniendo el string con el valor del campo XF
  const xf_data = _.get(nextModel, ['XF'], false) ? JSON.parse(nextModel.XF) : {};
  if (
    _.size(_.keys(xf_data)) > 0 &&
    !that.state.validateXf &&
    !_.isUndefined(xf_data[that.props.name]) &&
    xf_data[that.props.name] !== that.state.value
  ) {
    that.setState({ value: data, validateXf: true });
    that.props.dispatch(
      onChangeModelActions(that.props.formid, {
        name: that.props.name,
        value: xf_data[that.props.name],
      }),
    );
  }
};

export const cleanValue = (that, newProps = {}, defaultValue) => {
  if (
    !_.isEmpty(_.get(that, 'form.acciones.acciones_alert')) &&
    Object.keys(that.props.model).indexOf('UUID') < 0 &&
    that.type !== 'hidden'
  ) {
    if (that.form.acciones.acciones_alert.length > 0) {
      let _clean = true;
      that.form.acciones.acciones_alert.forEach((item) => {
        if (item.tipo !== 'alert') _clean = false;
      });
      if (_clean) {
        let _value;
        if (defaultValue !== null) _value = defaultValue;
        if (defaultValue === null) _value = '';
        that.setState({ value: _value });
        that.props.dispatch(
          onChangeModelActions(that.props.formid, {
            name: that.props.name,
            value: _value,
          }),
        );
      }
      // let _dataEvents = objectPath.get(that.props,"data-events");
      // if(_dataEvents!==undefined && _dataEvents!=="") dispatchEvents(that);
    }
  }
};

export const validateFields = (value, that) => {
  let message = '';
  let clases = '';
  let errores = 0;

  if (
    (objectPath.get(that.props, 'required') === 'required' ||
      objectPath.get(that.props, 'required') === 'true') &&
    (value === '' || value === null) &&
    that.props.type !== 'divwin' &&
    that.props.type !== 'date'
  ) {
    message = 'Campo obligatorio';
    clases = 'required form-control';
    errores = 1;
  } else if (
    that.props.required &&
    that.props.required === 'required' &&
    that.props.type === 'date' &&
    that.state.value === '' &&
    value === ''
  ) {
    message = 'Campo obligatorio';
    clases = 'required form-control';
    errores = 1;
  } else if (
    that.props.type === 'divwin' &&
    (!value || value.value === '_none' || value.value === '') &&
    (objectPath.get(that.props, 'required') === 'required' ||
      objectPath.get(that.props, 'required') === 'true')
  ) {
    message = 'Campo obligatorio';
    clases = 'required form-control select-divwin';
    errores = 1;
  } else if (
    (objectPath.get(that.props, 'data-fecha_minima') !== undefined ||
      objectPath.get(that.props, 'data-fecha_maxima') !== undefined) &&
    typeof value === 'string' &&
    value !== ''
  ) {
    if (objectPath.get(that.props, 'data-fecha_minima') !== undefined) {
      const minima = objectPath.get(that.props, 'data-fecha_minima');
      const minimaMessage = minima.split('-');
      const minimaDate = new Date(minima);
      const choose = value.split('/');
      const chooseDate = new Date(choose[1] + '-' + choose[0] + '-' + choose[2]);
      if (minimaDate > chooseDate) {
        message = `Fecha mínima ${minimaMessage[2]}/${minimaMessage[1]}/${minimaMessage[0]}`;
        clases = 'warning form-control';
        errores = 1;
      }
    }
    if (objectPath.get(that.props, 'data-fecha_maxima') !== undefined) {
      const maxima = objectPath.get(that.props, 'data-fecha_maxima');
      const maximaMessage = maxima.split('-');
      const maximaDate = new Date(maxima);
      const choose = value.split('/');
      const chooseDate = new Date(choose[1] + '-' + choose[0] + '-' + choose[2]);
      if (maximaDate < chooseDate) {
        message = `Fecha máxima ${maximaMessage[2]}/${maximaMessage[1]}/${maximaMessage[0]}`;
        clases = 'warning form-control';
        errores = 1;
      }
    }
  } else if (
    (objectPath.get(that.props, 'data-fecha_hora_minima') !== undefined ||
      objectPath.get(that.props, 'data-fecha_hora_maxima') !== undefined) &&
    value !== ''
  ) {
    if (objectPath.get(that.props, 'data-fecha_hora_minima') !== undefined) {
      const minima = new Date(objectPath.get(that.props, 'data-fecha_hora_minima'));
      const choose = new Date(value);
      const minimaMessage = moment(minima).format('DD/M/YYYY HH:mm');
      if (minima > choose) {
        message = `Fecha y hora mínima ${minimaMessage}`;
        clases = 'warning form-control';
        errores = 1;
      }
    }
    if (objectPath.get(that.props, 'data-fecha_hora_maxima') !== undefined) {
      const maxima = new Date(objectPath.get(that.props, 'data-fecha_hora_maxima'));
      const choose = new Date(value);
      const maximaMessage = moment(maxima).format('DD/M/YYYY HH:mm');
      if (maxima < choose) {
        message = `Fecha y hora máxima ${maximaMessage}`;
        clases = 'warning form-control';
        errores = 1;
      }
    }
  } else if (
    (objectPath.get(that.props, 'data-hora_minima') !== undefined ||
      objectPath.get(that.props, 'data-hora_maxima') !== undefined) &&
    typeof value === 'string' &&
    value !== ''
  ) {
    const valid = value.match(/^([01]\d|2[0-3]):([0-5]\d)$/g);
    if (valid === null) {
      message = 'Escribí un formato válido (22:05)';
      clases = 'warning form-control';
      errores = 1;
    } else {
      if (objectPath.get(that.props, 'data-hora_minima') !== undefined) {
        const minima = objectPath.get(that.props, 'data-hora_minima');
        if (minima > value) {
          message = `Hora mínima ${minima}`;
          clases = 'warning form-control';
          errores = 1;
        }
      }
      if (objectPath.get(that.props, 'data-hora_maxima') !== undefined) {
        const maxima = objectPath.get(that.props, 'data-hora_maxima');
        if (maxima < value) {
          message = `Hora máxima ${maxima}`;
          clases = 'warning form-control';
          errores = 1;
        }
      }
    }
  } else if (
    that.props.minlength !== undefined &&
    typeof value === 'string' &&
    value.length < parseInt(that.props.minlength)
  ) {
    message = `El mínimo son ${that.props.minlength} caracteres`;
    clases = 'warning form-control';
    errores = 1;
  } else if (
    that.props.maxlength !== undefined &&
    typeof value === 'string' &&
    value.length > parseInt(that.props.maxlength)
  ) {
    message = `El máximo son ${that.props.maxlength} caracteres`;
    clases = 'warning form-control';
    errores = 1;
  } else if (
    that.props.type === 'number' &&
    objectPath.get(that.props, 'min') &&
    parseInt(value) < parseInt(that.props.min)
  ) {
    message = `El número mínimo es ${that.props.min}`;
    clases = 'warning form-control';
    errores = 1;
  } else if (
    that.props.type === 'number' &&
    objectPath.get(that.props, 'max') !== undefined &&
    parseInt(Math.ceil(value)) > parseInt(that.props.max)
  ) {
    message = `El número máximo es ${that.props.max}`;
    clases = 'warning form-control';
    errores = 1;
  } else if (
    that.props.type === 'email' &&
    !emailValidator(value) &&
    value !== ''
  ) {
    message = 'Ingrese un email valido';
    clases = 'warning form-control';
    errores = 1;
  }

  that.setState({
    messageField: message,
    value: value,
    clases: clases,
    errores: errores,
  });

  return !errores;
};

const emailValidator = (value) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(value).toLowerCase());
};
